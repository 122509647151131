import React from 'react'
import { navigate } from 'gatsby'
import DefaultLayout from '../layouts/defaultLayout'

const ErrorPage = () => {
  if (typeof window !== `undefined`) {
    navigate('/')
  }
  return <DefaultLayout></DefaultLayout>
}

export default ErrorPage
